import { TOGGLE_TOGGLE } from "src/services/compareSerp/compareSerpConstants";

// The initial application state
const initialState = {
	theToggle: false
};

// Takes care of changing the application state
function reducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_TOGGLE: {
			return {
				...state,
				theToggle: !state.theToggle
			};
		}

		default:
			return {
				...state
			};
	}
}

export default reducer;
