// import store from "src/store";
// export const wrapRootElement = store;
// export { default as wrapRootElement } from "src/store";

// Allow redux
import store from "src/store";

export const wrapRootElement = store;
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
	// If we're on a hash link, do default browser behaviour
	if (location.hash !== "") {
		return false;
	}

	// Otherwise jump us to the top.

	// Why am I having to code this?
	return [0, 0];
};
