import React from "react";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";

import { composeWithDevTools } from "redux-devtools-extension";

import createSagaMiddleware from "redux-saga";
import reducers from "src/store/reducer";
import saga from "src/services/allSaga";

export default ({ element }) => {
	const middleware = createSagaMiddleware();
	const store = createStore(
		reducers,
		composeWithDevTools(applyMiddleware(middleware))
	);

	middleware.run(saga);

	return <Provider store={store}>{element}</Provider>;
};
